
import { defineComponent, computed } from 'vue'

import ViewLayout from '@/components/particles/common/ViewLayout.vue'
import SettingsMenu from '@/components/particles/SettingsLayout/SettingsMenu.vue'
import useStore from '@/store'

export default defineComponent({
  name: 'SettingsLayout',
  components: { ViewLayout, SettingsMenu },
  setup() {
    const store = useStore()
    const personalData = computed(() => store.state.userSettings.personalInfo)
    console.log(personalData)
    return {
      personalData,
    }
  },
})
