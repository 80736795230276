import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pt-8 pb-4 px-2.5 bg-white rounded-lg max-w-294 w-full" }
const _hoisted_2 = { class: "mb-7 text-center" }
const _hoisted_3 = { class: "mb-1 text-red text-base-title font-medium" }
const _hoisted_4 = {
  key: 0,
  class: "text-base text-darkGrey"
}
const _hoisted_5 = {
  key: 1,
  class: "text-base text-darkGrey"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderItem = _resolveComponent("HeaderItem")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.personalData.firstname) + " " + _toDisplayString(_ctx.personalData.lastname), 1),
      (_ctx.personalData.roles && _ctx.personalData.roles.includes('ROLE_ADMIN'))
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, "Administrateur"))
        : (_openBlock(), _createElementBlock("p", _hoisted_5, "Utilisateur"))
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_HeaderItem, {
        class: "mb-1 items-center",
        iconName: "bell",
        iconSize: "icon-sm",
        linkTitle: _ctx.$t('SETTINGS_MY_INFORMATION_LABEL'),
        "route-link": "MySettingsView",
        "active-routes": ['MySettingsView']
      }, null, 8, ["linkTitle"]),
      _createVNode(_component_HeaderItem, {
        class: "mb-1 items-center",
        iconName: "bell",
        iconSize: "icon-sm",
        linkTitle: _ctx.$t('SETTINGS_PASSWORD_LABEL'),
        "route-link": "PasswordSettingsView",
        "active-routes": ['PasswordSettingsView']
      }, null, 8, ["linkTitle"]),
      (_ctx.personalData.roles && _ctx.personalData.roles.includes('ROLE_ADMIN'))
        ? (_openBlock(), _createBlock(_component_HeaderItem, {
            key: 0,
            class: "mb-1 items-center",
            iconName: "bell",
            iconSize: "icon-sm",
            linkTitle: _ctx.$t('SETTINGS_USERS_LABEL'),
            "route-link": "UsersSettingsView",
            "active-routes": ['UsersSettingsView']
          }, null, 8, ["linkTitle"]))
        : _createCommentVNode("", true)
    ])
  ]))
}