
import { defineComponent } from 'vue'

import HeaderItem from '@/components/particles/MainLayout/Header/HeaderItem.vue'

export default defineComponent({
  name: 'SettingsMenu',
  components: { HeaderItem },
  props: {
    personalData: {
      type: Object,
      default: () => ({ firstname: '', lastname: '', email: '', phoneNumber: '', roles: ['ROLE_USER'] }),
    },
  },
})
