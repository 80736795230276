import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between items-start gap-x-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsMenu = _resolveComponent("SettingsMenu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_ViewLayout = _resolveComponent("ViewLayout")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_ViewLayout, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_SettingsMenu, { personalData: _ctx.personalData }, null, 8, ["personalData"]),
          _createVNode(_component_router_view, null, {
            default: _withCtx(({ Component }) => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]),
            _: 1
          })
        ])
      ]),
      _: 1
    })
  ]))
}